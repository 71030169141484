import React, { Component, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Swal from 'sweetalert2';
import { DataTable } from 'primereact/datatable';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Column } from 'primereact/column';
import { RadioButton } from 'primereact/radiobutton';
import { useNavigate, useParams } from 'react-router-dom';
import { getItemByPo, getPoAll } from '../../Service/SapService';
import { getInspReportAll } from '../../Service/InspReportService';
import { getIqcById, updateIqc } from '../../Service/IqcService';
import { getInspFfrAll } from '../../Service/InspFfrService';
import { getQualityIssueAll } from '../../Service/QualityIssueService';
import { getMetaValueAll } from '../../Service/MetaValueService';
import PageOverlay from '../../Components/Overlay/PageOverlay';
import { TabView, TabPanel } from 'primereact/tabview';
const isMFI = localStorage.getItem('selectedCompany');
const { $ } = window;
const localState = {};

const IncomingEditScreen = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { register, handleSubmit, formState: { errors }, control } = useForm();
    const [processing, setProcessing] = useState(false);
    const [ poNumber, setPoNumber ] = useState(null);
    const [ poList, setPoList ] = useState([]);
    const [ itemCodes, setItemCodes ] = useState([]);
    const [ inspReport, setInspReport ] = useState([]);
    const [ whprod, setWhProd ] = useState([]);
    const [ whfg, setWhFg ] = useState([]);
    const [ whdestfg, setWhDestFg ] = useState([]);
    const [ whdestprod, setWhDestProd ] = useState([]);
    const [ iqcData, setIqcData ] = useState({
        id_aql: 1,
        inspdate: "",
        title: "",
        ponumber: "",
        grnumber: "",
        itemcode: "",
        itemname: "",
        itemdesc: "",
        lotorder: "",
        lotqty: 0,
        serialno: "",
        assyproc: "",
        insprule: "",
        inspqty: 0,
        objtype: 0,
        docentry: 0,
        linenum: 1,
        whfrom: "",
        whdest: "",
        is_changed: false,
        changenote: "",
        conclusion: "",
        inspstatus: 0,
        data: [],
        transfer: [
            {
                "whfrom": "",
                "whdest": "",
                "qty": 0,
                "type": 0,
                "description": "Sampling",
            },
            {
                "whfrom": "",
                "whdest": "",
                "qty": 0,
                "type": 1,
                "description": "No Check",
            },
        ]
    });
    const [ qualityIssue, setQualityIssue ] = useState({
        issue: "-",
        lot_order: "-",
        defect_qty: "-",
        ffr_status: "-",
        defect_rate: "-"
    });

    const onSubmit = data => {
        setProcessing(true);
        const dataTemp = [];
        var flag = true;
        for (var key in inspReport) {
            if(inspReport[key].judgement != ""){
                dataTemp.push({
                    id_report: inspReport[key].id,
                    standard: inspReport[key].aktual_standard,
                    judgement: inspReport[key].judgement
                });
            } else {
                flag = false;
            }
        }
        if(flag) {
            iqcData.data = dataTemp;
            const response = updateIqc(id, iqcData);
            response.then(res => {
                if (res.status == 200 || res.status == 201) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Save data success',
                        text: 'Data has been saved!'
                    });

                    navigate("/incoming");
                }

                setProcessing(false);
            }).catch(({response: { data } }) => {
                if(data.status == 422){
                    const obj = Object.keys(data.errors);
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: data.errors[obj[0]][0]
                    });
                }else
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Save data error!'
                    });

                setProcessing(false);
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please fill all judgement data!'
            });
        }
    }

    const setWhProdVar = (e) => {
        if(e == "WH-JTSTG") {
            getMetaValueAll({'filter':'meta_function:whdestfg', 'search':e.substring(0, 5)}).then(res => {
                setWhDestFg(res.data.data);
            });

            getMetaValueAll({'filter':'meta_function:whdestprod', 'search':e.substring(0, 5)}).then(res => {
                setWhDestProd(res.data.data);
            });

            setIqcData({...iqcData, whfrom: e});
        } else {
            // setIqcData({...iqcData, whfrom: e, transfer: []});

            getMetaValueAll({'filter':'meta_function:whfg', 'search':e.substring(0, 5)}).then(res => {
                setWhDestFg(res.data.data);
            });
            getMetaValueAll({'filter':'meta_function:whprod', 'search':e.substring(0, 5)}).then(res => {
                setWhDestProd(res.data.data);
            });

            setIqcData({...iqcData, whfrom: e});
        }
    }

    const onChangeInspRule = (e) => {
        const whValue = iqcData.whfrom;
        if(e == "N" && (iqcData.whfrom.endsWith("STG") || iqcData.whfrom.endsWith("PRD"))) {
            getMetaValueAll({'filter':'meta_function:whfg', 'search':whValue.substring(0, 5)}).then(res => {
                setWhDestFg(res.data.data);
            });

            setIqcData({...iqcData, 
                insprule: e, 
                transfer: [
                    {
                        "whdest": "",
                        "qty": iqcData.lotqty,
                        "type": 1,
                        "description": "No Check",
                    },
                ]
            });
        } else if(e == "S" && (iqcData.whfrom.endsWith("STG") || iqcData.whfrom.endsWith("PRD"))) {
            if (whValue == "WH-JTSTG") {
                getMetaValueAll({'filter':'meta_function:whdestfg', 'search':whValue.substring(0, 5)}).then(res => {
                    setWhDestFg(res.data.data);
                });
                getMetaValueAll({'filter':'meta_function:whdestprod', 'search':whValue.substring(0, 5)}).then(res => {
                    setWhDestProd(res.data.data);
                });
            } else {
                getMetaValueAll({'filter':'meta_function:whfg', 'search':whValue.substring(0, 5)}).then(res => {
                    setWhDestFg(res.data.data);
                });
                getMetaValueAll({'filter':'meta_function:whprod', 'search':whValue.substring(0, 5)}).then(res => {
                    setWhDestProd(res.data.data);
                });
            }

            setIqcData({...iqcData, 
                insprule: e, 
                transfer: [
                    {
                        "whdest": "",
                        "qty": iqcData.inspqty,
                        "type": 0,
                        "description": "Sampling",
                    },
                    {
                        "whdest": "",
                        "qty": iqcData.lotqty - iqcData.inspqty,
                        "type": 1,
                        "description": "No Check",
                    },
                ]
            });
        } else if((e == "Y" || e == "R") && (iqcData.whfrom.endsWith("STG") || iqcData.whfrom.endsWith("PRD"))) {
            if(whValue == "WH-JTSTG") {
                getMetaValueAll({'filter':'meta_function:whfg', 'search':whValue.substring(0, 5)}).then(res => {
                    setWhDestFg(res.data.data);
                });
            } else {
                getMetaValueAll({'filter':'meta_function:whprod', 'search':whValue.substring(0, 5)}).then(res => {
                    setWhDestProd(res.data.data);
                });
            }
            setIqcData({...iqcData, 
                insprule: e, 
                transfer: [
                    {
                        "whdest": "",
                        "qty": iqcData.lotqty,
                        "type": 0,
                        "description": "Full Check",
                    }
                ]
            });
        } else {
            setIqcData({...iqcData, insprule: e});
        }
    }

    const onChangeInspQty = (e) => {
        const lotQty = iqcData.lotqty;
        const numericValue = parseInt(e, 10);
        const validatedValue = isNaN(numericValue) ? "" : Math.max(0, Math.min(numericValue, lotQty));
        if(iqcData.insprule == "N" && iqcData.whfrom.endsWith("STG")) {
            setIqcData({...iqcData, 
                inspqty: e, 
                transfer: [
                    {
                        "whdest": "",
                        "qty": iqcData.lotqty,
                        "type": 1,
                        "description": "No Check",
                    },
                ]
            });
        } else if(iqcData.insprule == "S" && (iqcData.whfrom.endsWith("STG") || iqcData.whfrom.endsWith("PRD"))) {
            setIqcData({...iqcData, 
                inspqty: validatedValue, 
                transfer: [
                    {
                        "whdest": "",
                        "qty": validatedValue,
                        "type": 0,
                        "description": "Sampling",
                    },
                    {
                        "whdest": "",
                        "qty": iqcData.lotqty - validatedValue,
                        "type": 1,
                        "description": "No Check",
                    },
                ]
            });
        } else if((iqcData.insprule == "Y" || iqcData.insprule == "R") && iqcData.whfrom.endsWith("STG")) {
            setIqcData({...iqcData, 
                inspqty: e, 
                transfer: [
                    {
                        "whdest": "",
                        "qty": iqcData.lotqty,
                        "type": 0,
                        "description": "Full Check",
                    }
                ]
            });
        } else {
            setIqcData({...iqcData, inspqty: e});
        }
    }

    const onGoback = () => {
        navigate(-1);
    }

    const judgementTemplate = (options) => {
        return <select className={`form-control`} onChange={(e) => {
            inspReport.filter(insp => insp.id == options.id).map((insp) => {
                insp.judgement = e.target.value;
            });
        }}>
            <option value="" selected={options.judgement == "" ? true : false}>-</option>
            <option value="OK" selected={options.judgement == 'OK' ? true : false}>OK</option>
            <option value="NG" selected={options.judgement == 'NG' ? true : false}>NG</option>
        </select>;
    }

    const aktualTemplate = (options) => {
        return <input onChange={(e) => {
            inspReport.filter(insp => insp.id == options.id).map((insp) => {
                insp.aktual_standard = e.target.value;
            })}} className='form-control' defaultValue={options.aktual_standard} />
    }

    const headerTemplate = (data) => {
        return (
            <div>
                <h6 style={{marginTop: '8px', marginLeft: '10px'}}>{data.inspcategname}</h6>
            </div>
        );
    }

    let headerColumnGroup = <ColumnGroup>
        <Row>
            <Column field="no" header="No" alignHeader="center" style={{ width: '5%' }} rowSpan={2}/>
            <Column field="name" header="Item Pemeriksaan" alignHeader="center" rowSpan={2}/>
            <Column field="specification" header="Spesifikasi" alignHeader="center" rowSpan={2}/>
            {/* <Column field="standard" header="Standard" alignHeader="center" rowSpan={2}/> */}
            <Column header="Hasil" alignHeader="center" colSpan={2} />
        </Row>
        <Row>
            <Column header="Aktual" align="center" alignHeader="center" field="aktual_standard" style={{ width: '25%' }}/>
            <Column header="Judge" align="center" alignHeader="center" field="judgement" style={{ width: '10%', minWidth: '85px' }}/>
        </Row>
    </ColumnGroup>;

    const getIqcData = (id) => {
        getIqcById(id).then(res => {
            setIqcData(res.data);
            setInspReport(res.data.data);

            // getMetaValueAll({'filter':'meta_function:whdestfg', 'search':res.data.whfrom.substring(0, 5)}).then(res => {
            //     setWhDestFg(res.data.data);
            // });
    
            // getMetaValueAll({'filter':'meta_function:whdestprod', 'search':res.data.whfrom.substring(0, 5)}).then(res => {
            //     setWhDestProd(res.data.data);
            // });

            if(isMFI == 'MI') {
                getMetaValueAll({'filter':'meta_function:whdestfg', 'search':res.data.whfrom.substring(0, 5)}).then(res => {
                    setWhDestFg(res.data.data);
                });
        
                getMetaValueAll({'filter':'meta_function:whdestprod', 'search':res.data.whfrom.substring(0, 5)}).then(res => {
                    setWhDestProd(res.data.data);
                });
            } else {
                getMetaValueAll({'filter':'meta_function:whprod', 'search':res.data.whfrom.substring(0, 5)}).then(res => {
                    setWhDestFg(res.data.data);
                });
                getMetaValueAll({'filter':'meta_function:whprod', 'search':res.data.whfrom.substring(0, 5)}).then(res => {
                    setWhDestProd(res.data.data);
                });
            }

            if(res.data.ffr_id != null && res.data.ffr_id != ""){
                getInspFfrAll({'filter': 'id:'+res.data.ffr_id}).then(res => {
                    if(res.data.data.length > 0){
                        setQualityIssue({...qualityIssue, 
                            ffr_status: res.data.data[0].ffr_rate * 100 + " %"
                        });
                    } else {
                        setQualityIssue({...qualityIssue, 
                            ffr_status: "-"
                        });
                    }
                });
            }

            if(res.data.quality_issue_id != null && res.data.quality_issue_id != ""){
                getQualityIssueAll({'filter': 'id:'+res.data.quality_issue_id}).then(res => {
                    if(res.data.data.length > 0){
                        setQualityIssue({...qualityIssue, 
                            issue: res.data.data[0].issue,
                            lot_order: res.data.data[0].lotorder,
                            defect_qty: res.data.data[0].defqty,
                            defect_rate: res.data.data[0].defrate
                        });
                    } else {
                        setQualityIssue({...qualityIssue, 
                            issue: "-",
                            lot_order: "-",
                            defect_qty: "-",
                            defect_rate: "-"
                        });
                    }
                });
            }
        });
    }

    const getOptions = () => {
        getMetaValueAll({'filter':'meta_function:whprod'}).then(res => {
            setWhProd(res.data.data);
        });

        const promise1 = getMetaValueAll({'filter':'meta_function:whprod,is_active:1'});
        const promise2 = getMetaValueAll({'filter':'meta_function:whfg,is_active:1'});

        Promise.all([promise1, promise2]).then(function(values) {
            setWhProd(values[0].data.data);
            setWhFg(values[1].data.data);
        });
    }

    let groupedData = {};
    let newData = [];
    if (iqcData && iqcData.data) {
        newData = iqcData.data;
    
        // Ensure newData is an array
        if (Array.isArray(newData)) {
            groupedData = groupDataByPartName(newData);
            // console.log('ini groupedData before', groupedData);
        } else {
            console.error('newData is not an array');
        }
    } else {
        console.error('iqcData or iqcData.data is undefined');
    }

    function groupDataByPartName(newData) {
        const groupedData = {};
        newData.forEach(item => {
            if (item.part_code != null && item.part_code != '') {
                const categname = item.part_code;
                if (!groupedData[categname]) {
                    groupedData[categname] = [];
                }
                groupedData[categname].push(item);
            }
        });
        return groupedData;
    }

    useEffect(() => {
        getIqcData(id);
        getOptions();
    }, []);
    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Manage IQC</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">IQC</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <PageOverlay display={processing} />
                <div className="container-fluid">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='card'>
                            <div className='card-header'>
                                <div className='card-title'><i className='fa fa-tools' /> Edit IQC</div>
                            </div>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <div className='form-group'>
                                            <label>GR - PO</label>
                                            <div>
                                                <span>{iqcData.docnumber}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='form-group'>
                                            <label>PO No</label>
                                            <div>
                                                <span>{iqcData.ponumber}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='form-group'>
                                            <label>Model</label>
                                            <div>
                                                <span>{iqcData.itemname}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ height: 1, background: '#ccc', margin: '10px 0 20px 0' }} />
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label htmlFor='lot-order'>Serial</label>
                                            <input id="lot-order" className='form-control' value={iqcData.serialno} onChange={(e) => setIqcData({...iqcData, serialno: e.target.value})}/>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label>Assy. Process</label>
                                            <select className='form-control' onChange={e => setIqcData({...iqcData, assyproc: e.target.value})} >
                                                <option value="" selected={iqcData.assyproc == "" ? true : false}></option>
                                                <option value="CBU" selected={iqcData.assyproc == 'CBU' ? true : false}>CBU</option>
                                                <option value="CKD" selected={iqcData.assyproc == 'CKD' ? true : false}>CKD</option>
                                                <option value="SKD" selected={iqcData.assyproc == 'SKD' ? true : false}>SKD</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label htmlFor='tanggal-tiba'>Tanggal Tiba</label>
                                            <input type="date" id="tanggal-tiba" className='form-control' placeholder='Iqcing Name' value={iqcData.arrivaldate} onChange={(e) => setIqcData({...iqcData, arrivaldate: e.value})}/>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label htmlFor='tanggal-uji'>Tanggal Uji</label>
                                            <input type="date" id="tanggal-uji" className='form-control' value={iqcData.inspdate} onChange={(e) => setIqcData({...iqcData, inspdate: e.target.value})}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label htmlFor='lot-order'>Lot Order</label>
                                            <input id="lot-order" className='form-control' value={iqcData.lotorder} onChange={(e) => setIqcData({...iqcData, lotorder: e.target.value})}/>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label htmlFor='lot-qty'>Lot Qty</label>
                                            <input type="number" min="0" id="lot-qty" className='form-control' value={iqcData.lotqty} onChange={(e) => setIqcData({...iqcData, lotqty: e.target.value})}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label>WH Prod</label>
                                            <select className='form-control' onChange={e => setWhProdVar(e.target.value)} >
                                                <option value="" selected={iqcData.whfrom == "" ? true : false}></option>
                                                {(whprod || []).map((e, i) => <option key={'key_wh_prod' + i} value={e.meta_value} selected={iqcData.whfrom == e.meta_value ? true : false}>{e.meta_description}</option>)}
                                                {/* <option value="WH-KWPRD" selected={iqcData.whfrom == 'WH-KWPRD' ? true : false}>[WH-KWPRD] Warehouse Karawaci</option>
                                                <option value="WH-TGPRD" selected={iqcData.whfrom == 'WH-TGPRD' ? true : false}>[WH-TGPRD] Warehouse Tangerang</option>
                                                <option value="WH-JTPRD" selected={iqcData.whfrom == 'WH-JTPRD' ? true : false}>[WH-JTPRD] Warehouse Jatake</option> */}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label>WH FG</label>
                                            <select className='form-control' onChange={e => setIqcData({...iqcData, whdest: e.target.value})} >
                                                <option value="" selected={iqcData.whdest == "" ? true : false}></option>
                                                {(whfg || []).map((e, i) => <option key={'key_wh_fg' + i} value={e.meta_value} selected={iqcData.whdest == e.meta_value ? true : false}>{e.meta_description}</option>)}
                                                {/* <option value="WH-KWFGA" selected={iqcData.whdest == 'WH-KWFGA' ? true : false}>[WH-KWFGA] Warehouse Finished Goods Grade A Karawaci</option>
                                                <option value="WH-TGFGA" selected={iqcData.whdest == 'WH-TGFGA' ? true : false}>[WH-TGFGA] Warehouse Finished Goods Grade A Tangerang</option>
                                                <option value="WH-JTFGA" selected={iqcData.whdest == 'WH-JTFGA' ? true : false}>[WH-JTFGA] Warehouse Finished Goods Grade A Jatake</option>
                                                <option value="WH-JTP04" selected={iqcData.whdest == 'WH-JTP04' ? true : false}>[WH-JTP04] Warehouse Perlengkapan Penunjang Produksi Jatake</option>
                                                <option value="WH-JTRWK" selected={iqcData.whdest == 'WH-JTRWK' ? true : false}>[WH-JTRWK] Warehouse Rework Jatake</option> */}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ height: 1, background: '#ccc', margin: '20px 0' }} />
                                <div className='row'>
                                    <div className='col-md-2'>
                                        <div className="form-group">
                                            <label>Perubahan</label>
                                            <label class="form-control">
                                                <input type="radio" onChange={(e) => setIqcData({...iqcData, is_changed: 0})} checked={iqcData.is_changed === 0}/> Ada
                                            </label>
                                            <label class="form-control">
                                                <input type="radio" onChange={(e) => setIqcData({...iqcData, is_changed: 1})} checked={iqcData.is_changed === 1}/> Tidak
                                            </label>
                                            <label class="form-control">
                                                <input type="radio" onChange={(e) => setIqcData({...iqcData, is_changed: 2})} checked={iqcData.is_changed === 2}/> Baru
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-md-5'>
                                        <div className='form-group'>
                                            <label htmlFor='lot-order'>Informasi</label>
                                            <textarea id="lot-order" className='form-control' rows={3} value={iqcData.changenote} onChange={(e) => setIqcData({...iqcData, changenote: e.target.value})}/>
                                        </div>
                                    </div>
                                    <div className='col-md-5'>
                                        <div className='form-group'>
                                            <label htmlFor='lot-qty'>Kesimpulan</label>
                                            <textarea id="lot-qty" className='form-control' rows={3} value={iqcData.conclusion} onChange={(e) => setIqcData({...iqcData, conclusion: e.target.value})}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div style={{ height: 1, background: '#ccc', margin: '20px 0' }} />
                                        <div className='form-group'>
                                            <label htmlFor='is-active'>List Report</label>
                                        {isMFI == 'MI' && (
                                            <div>
                                                <DataTable value={inspReport} size="small" responsiveLayout="scroll" showGridlines
                                                    headerColumnGroup={headerColumnGroup} alignHeader="center" align="center"
                                                    rowGroupMode="subheader" groupRowsBy="inspcategname" rowGroupHeaderTemplate={headerTemplate}>
                                                    <Column field="no" body={(data, options) => options.rowIndex + 1} align="center"/>
                                                    <Column field="name" />
                                                    <Column field="specification" />
                                                    {/* <Column field="standard" /> */}
                                                    <Column field="aktual" body={aktualTemplate}/>
                                                    <Column field="judgment" align="center" body={judgementTemplate}/>
                                                </DataTable>
                                            </div>
                                            )}
                                            {isMFI == 'MFI' && (
                                            <div className="card mt-2">
                                                <TabView  >
                                                        {Object.entries(groupedData).map(([department, departmentData], index) => {
                                                            return  (
                                                                <TabPanel key={index} header={departmentData.length > 0 ? departmentData[0].part_name : department} >
                                                                    <label htmlFor='is-active'>List Report</label>
                                                                    <div>
                                                                        <DataTable key={index} value={departmentData} size="small" responsiveLayout="scroll" showGridlines
                                                                            headerColumnGroup={headerColumnGroup} align="center"
                                                                            rowGroupMode="subheader" groupRowsBy="inspcategcode" rowGroupHeaderTemplate={headerTemplate}>
                                                                            <Column field="no" body={(data, options) => options.rowIndex + 1} align="center"/>
                                                                            <Column field="name" />
                                                                            <Column field="specification" />
                                                                            {/* <Column field="standard" /> */}
                                                                            <Column field="aktual" body={aktualTemplate}/>
                                                                            <Column field="judgment" align="center" body={judgementTemplate}/>
                                                                        </DataTable>
                                                                    </div>
                                                                </TabPanel>
                                                            )
                                                        })}
                                                    </TabView>
                                            </div>
                                        )}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ height: 1, background: '#ccc', margin: '20px 0' }} />
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='form-group'>
                                            <label htmlFor='is-active'>Quality Issue</label>
                                            <table className="table table-bordered">
                                                <tr>
                                                    <td style={{background: '#f8f9fa', textAlign: 'center', padding: '50px'}} rowSpan={2}>Quality Issue</td>
                                                    <td colSpan={4}><input type="text" className='form-control' style={{width: '100%', textAlign: 'center', background: '#f8f9fa'}} disabled value={qualityIssue.issue} /></td>
                                                </tr>
                                                <tr>
                                                    <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}}>Lot Order</td>
                                                    <td><input type="text" className='form-control' value={iqcData.lotorder} onChange={(e) => setIqcData({...iqcData, lotorder: e.target.value})} style={{width: '100%', textAlign: 'center', background: '#f8f9fa'}} disabled /></td>
                                                    <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}}>Defect Qty</td>
                                                    <td style={{width: '20%'}}><input type="text" className='form-control' style={{width: '100%', textAlign: 'center', background: '#f8f9fa'}} disabled value={qualityIssue.defect_qty} /></td>
                                                </tr>
                                                <tr>
                                                    <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}}>FFR Status</td>
                                                    <td colSpan={4}><input type="text" className='form-control' style={{width: '100%', textAlign: 'center', background: '#f8f9fa'}} disabled value={qualityIssue.ffr_status} /></td>
                                                </tr>
                                                <tr>
                                                    <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}}>Defect Rate</td>
                                                    <td colSpan={4}><input type="text" className='form-control' style={{width: '100%', textAlign: 'center', background: '#f8f9fa'}} disabled value={qualityIssue.defect_rate} /></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-5'>
                                        <div className='form-group'>
                                            <label htmlFor='is-active'>Keputusan QC</label>
                                            <table className="table table-bordered">
                                                <tr>
                                                    <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}} >Sampling Code</td>
                                                    <td>
                                                        <select className='form-control' style={{width: '100%'}} value={iqcData.objtype} onChange={(e) => setIqcData({...iqcData, objtype: e.target.value})} >
                                                            <option value=""></option>
                                                            <option value="73">100%</option>
                                                            <option value="72">20% atau 20 unit</option>
                                                            <option value="71" selected="">3% atau 10 unit</option>
                                                            <option value="70">6% atau 20 unit</option>
                                                            <option value="69">Non Inspeksi atau 1 unit</option>
                                                            <option value="74">1 unit dari 20 unit produksi</option>
                                                            <option value="75">1 unit dari tiap lot produksi</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}}>Std. Check</td>
                                                    <td>
                                                        <select className='form-control' style={{width: '100%'}} value={iqcData.insprule} onChange={(e) => onChangeInspRule(e.target.value)} >
                                                            <option value=""></option>
                                                            <option value="N">No Check</option>
                                                            <option value="S">Sampling</option>
                                                            <option value="Y">100% Check</option>
                                                            <option value="R">Rework</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}}>Qty Sampling</td>
                                                    <td><input type="number" className='form-control' min="0" style={{width: '100%'}} value={iqcData.inspqty} onChange={(e) => onChangeInspQty(e.target.value)} /></td>
                                                </tr>
                                                <tr>
                                                    <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}}>Status</td>
                                                    <td>
                                                        <select className='form-control' style={{width: '100%'}} value={iqcData.inspstatus} onChange={(e) => setIqcData({...iqcData, inspstatus: e.target.value})} >
                                                            <option value=""></option>
                                                            <option value="1">Draft</option>
                                                            <option value="2">Launch</option>
                                                            <option value="3">Hold</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div className='col-md-7'>
                                        <div className='form-group'>
                                            <label htmlFor='is-active'>Transfer</label>
                                            <table className="table table-bordered">
                                                <tr>
                                                    <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}}>WH Asal</td>
                                                    <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}}>WH Tujuan</td>
                                                    <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}}>Qty</td>
                                                    <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}}>Keterangan</td>
                                                </tr>
                                                { iqcData.insprule != "" && (
                                                    <>
                                                        {(iqcData.transfer || []).map((transfer,i) => <tr>
                                                            <td style={{width: '25%'}}><input type="text" className='form-control' style={{width: '100%', textAlign: 'center', background: '#f8f9fa'}} disabled value={iqcData.whfrom} /></td>
                                                            <td style={{width: '25%'}}>
                                                                <select className='form-control' onChange={e => transfer.whdest = e.target.value } >
                                                                    <option value="" selected={transfer.whdest == "" ? true : false}></option>
                                                                    { transfer.type == 1 && (
                                                                        <>
                                                                            {(whdestfg || []).map((e, i) => <option key={'key_wh_dest' + i} value={e.meta_value} selected={transfer.whdest == e.meta_value ? true : false}>{e.meta_description}</option>)}
                                                                        </>
                                                                    )}
                                                                    { transfer.type == 0 && (
                                                                        <>
                                                                            {(whdestprod || []).map((e, i) => <option key={'key_wh_dest' + i} value={e.meta_value} selected={transfer.whdest == e.meta_value ? true : false}>{e.meta_description}</option>)}
                                                                        </>
                                                                    )}
                                                                </select>
                                                            </td>
                                                            <td style={{width: '20%'}}><input type="text" className='form-control' style={{width: '100%', textAlign: 'center', background: '#f8f9fa'}} disabled value={transfer.qty} /></td>
                                                            <td style={{width: '30%'}}><input type="text" className='form-control' style={{width: '100%', textAlign: 'center', background: '#f8f9fa'}} disabled value={transfer.description} /></td>
                                                        </tr>) }
                                                    </>
                                                )}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div style={{ height: 1, background: '#ccc', margin: '20px 0' }} />
                                        <div className='form-group'>
                                            <div className='d-flex float-right'>
                                                <button type='button' className='btn btn-outline-dark' style={{ width: 100, marginRight: 20 }} onClick={onGoback}><i className='fa fa-reply' /> Back</button>
                                                <button type='submit' className='btn btn-dark' style={{ width: 150 }}><i className='fa fa-save' /> Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card-footer'></div>
                        </div>
                    </form>
                </div>

            </section>
        </div>
    );
}

export default IncomingEditScreen;